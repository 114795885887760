import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    // {
    //   yearRange: "2000 - 2004",
    //   title: "Computer Science",
    //   place: "International University",
    //   desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    // },
    // {
    //   yearRange: "2005 - 2008",
    //   title: "Bachelor Degree",
    //   place: "University of California",
    //   desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    // },
    // {
    //   yearRange: "2009 - 2012",
    //   title: "Master Degree",
    //   place: "Harvard University",
    //   desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    // },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - 2023",
      title: "Sr. Frontend Engineer",
      place: "Maritz",
      desc: [
        "Developed sophisticated user interfaces employing React and Vue frameworks, optimizing performance and enhancing user experience.",
        "Collaborated with backend developers on seamless API integrations, bridging frontend and backend functionality for cohesive application development.",
        "Mentored and supported a team of junior developers, fostering their growth in frontend technologies and best practices.",
        "Continuously refined tech stack and development processes, ensuring adaptability and alignment with industry trends and standards."
      ],
    },
    {
      yearRange: "2021 - 2022",
      title: "Full Stack Developer",
      place: "Timmermann Group",
      desc: [
        "Served as a subject matter expert in Vue and React, training junior developers and streamlining documentation practices throughout the organization, while actively participating in the entire product development lifecycle.",
        "Delivered web solutions for 10+ domestic and international clients across diverse industries within the first year, utilizing Vue, TypeScript, and headless WordPress for CMS, ranging from streamlined SPAs to comprehensive eCommerce applications.",
        "Partnered with UX/UI design teams to enhance user experience, emphasizing responsive design, ADA compliance, and performance optimization across web modules.",
        "Leveraged extensive knowledge in React, Node.js, and PHP to create robust, scalable, and maintainable full-stack applications, ensuring adaptability to client needs and industry standards."
      ],
    },
    {
      yearRange: "2018 - 2021",
      title: "Front End Engineer",
      place: "Panera",
      desc: [
        "Co-innovated and developed Project Atajo, a product suggestion engine utilizing analytics, recent order data, and targeted product campaigns, resulting in a projected $9 million increase in annual sales.",
        "Pioneered the initial implementation of Panera's coffee subscription signup and management flows in 2019, establishing the foundation for the company's first subscription-based model.",
        "Collaborated on the development and presentation of Panera's 'Voice Ordering' feature for Alexa and Google Assistant, leveraging JavaScript, AWS, and GCP APIs to deliver seamless user experiences.",
        "Actively mentored junior developers both during and after work hours, fostering their growth in frontend technologies and best practices."
      ],
    }
  ];

  const skills = [
    {
      name: "JavaScript",
      percent: 90,
    },
    {
      name: "TypeScript",
      percent: 90,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "React JS",
      percent: 80,
    },
    {
      name: "Vue JS",
      percent: 75,
    },
    {
      name: "Node JS",
      percent: 80,
    },
    {
      name: "Express JS",
      percent: 80,
    },
    {
      name: "MongoDB",
      percent: 75,
    },
    {
      name: "Bootstrap / TailwindCSS",
      percent: 80,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            {/* <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2> */}
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <ul className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                  {value.desc.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
